// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-code-tsx": () => import("./../../../src/pages/code.tsx" /* webpackChunkName: "component---src-pages-code-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-foundation-members-tsx": () => import("./../../../src/pages/foundation/members.tsx" /* webpackChunkName: "component---src-pages-foundation-members-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-users-tsx": () => import("./../../../src/pages/users.tsx" /* webpackChunkName: "component---src-pages-users-tsx" */),
  "component---src-templates-doc-tsx": () => import("./../../../src/templates/doc.tsx" /* webpackChunkName: "component---src-templates-doc-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

